@import "reset.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

html {
  font-size: 10px;
}

/*.monochrome {*/
/*  filter: grayscale(100%);*/
/*}*/

/*html.contrast * {*/
/*  background: black !important;*/
/*  color: yellow !important;*/
/*}*/

/*html.contrast * .MuiButtonBase-root, html.contrast * .MuiButtonBase-root * {*/
/*  background: gray !important;*/
/*  color: yellow !important;*/
/*}*/

/*html.contrast * .MuiTouchRipple-root {*/
/*  display: none !important;*/
/*}*/

/*html.contrast * .MuiInputBase-root fieldset {*/
/*  border: 1px solid yellow !important;*/
/*}*/

body {
  margin: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #FFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


